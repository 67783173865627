import gql from 'graphql-tag'


const docConfigResponse = `
  id type docType isDefault
  contactId contact {id type code name}
`

export const LIST_DOC_CONFIG_CONTACT_ID = (templateType) => gql`query LIST_DOC_CONFIG ($docType: String!, $q: FilterInput) {
  items: list${templateType}DocConfigContactId (docType: $docType, q: $q) {${docConfigResponse}}
}`

export const LIST_DOC_CONFIG_CONTACT_ID_DROPDOWN_CONTACT_TYPE = (templateType) => gql`query LIST_DOC_CONFIG_CONTACT_ID_DROPDOWN_CONTACT_TYPE ($docType: String!) {
  types: list${templateType}DocConfigContactIdDropdownContactType (docType: $docType) {
    text value
  }
}`

export const LIST_DOC_CONFIG_CONTACT_ID_DROPDOWN_CONTACT = (templateType) => gql`query LIST_DOC_CONFIG_CONTACT_ID_DROPDOWN_CONTACT ($docType: String!, $q: FilterInput) {
  items: list${templateType}DocConfigContactIdDropdownContact (docType: $docType, q: $q) {
    id type code name
  }
}`

export const CREATE_DOC_CONFIG_CONTACT_ID = (templateType) => gql`mutation CREATE_DOC_CONFIG_CONTACT_ID ($docType: String!, $input: ${templateType}DocConfigContactIdInput!) {
  item: create${templateType}DocConfigContactId (docType: $docType, input: $input) {${docConfigResponse}}
}`

export const UPDATE_DOC_CONFIG_CONTACT_ID = (templateType) => gql`mutation UPDATE_DOC_CONFIG_CONTACT_ID ($docType: String!, $docConfigId: Int!, $input: ${templateType}DocConfigContactIdInput!) {
  item: update${templateType}DocConfigContactId (docType: $docType, docConfigId: $docConfigId, input: $input) {${docConfigResponse}}
}`

export const DESTROY_DOC_CONFIG_CONTACT_ID = (templateType) => gql`mutation DESTROY_DOC_CONFIG_CONTACT_ID ($docType: String!, $docConfigId: Int!) {
  item: destroy${templateType}DocConfigContactId (docType: $docType, docConfigId: $docConfigId) {id}
}`