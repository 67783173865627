<template>
  <div class="">
    <div class="card" v-if="!toggle && item">
      <div class="card-body">
        <span class="card-text">
          <span
            class="mx-1 pointer float-right"
            @click="toggle = !toggle">
            <fa icon="pencil-alt" class="text-warning"></fa>
          </span>
          <div class="pre-line">
            {{formData.contact.code}} ({{formData.contact.name}})
            <span v-if="formData.isDefault" class="text-success"> --- ค่าเริ่มต้น</span>
          </div>
        </span>
      </div>
    </div>

    <div class="card" v-if="toggle">
      <div class="card-body" v-if="toggle">
        <div class="card-text">
          <div class="form-row">
            <DocConfigContactIdCardContact
              :templateType="templateType"
              :docType="docType"
              class="col-12"
              select="id"
              v-model="formData.contactId"
              :validations="[
                {text: 'required!', value: $v.formData.contactId.$dirty && !$v.formData.contactId.required}
              ]">
            </DocConfigContactIdCardContact>
          </div>

          <div class="form-group">
            <sgv-input-check
              class="form"
              label="ค่าเริ่มต้น"
              inline
              v-model="formData.isDefault">
            </sgv-input-check>
          </div>

          <button
            v-if="isForm"
            type="button"
            class="btn btn-success"
            @click="createData">
            เพิ่ม
          </button>

          <button
            v-if="!isForm"
            type="button"
            class="btn btn-warning"
            @click="updateData">
            แก้ไข
          </button>

          <button
            v-if="toggle"
            type="button"
            class="btn btn-primary ml-2"
            @click="cancelData">
            ยกเลิก
          </button>

          <button
            v-if="!isForm"
            type="button"
            class="btn btn-danger ml-2 float-right"
            @click="destroyData">
            ลบ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigContactIdCardContact from './DocConfigContactIdCardContact'
import {
  CREATE_DOC_CONFIG_CONTACT_ID,
  UPDATE_DOC_CONFIG_CONTACT_ID,
  DESTROY_DOC_CONFIG_CONTACT_ID,
} from './graph/docConfig.contactId'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
    isForm: {
      type: Boolean,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        contactId: null,
        contact: null,
        isDefault: true
      }
    }
  },
  validations: {
    formData: {
      contactId: {required},
    }
  },
  methods: {
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return {
        contactId: v.contactId,
        isDefault: v.isDefault
      }
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_CONTACT_ID(this.templateType),
        variables: {
          docType: this.docType,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$emit('created', res.data.item)
        this.toggle = false
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_DOC_CONFIG_CONTACT_ID(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.item.id,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$emit('updated', res.data.item)
        this.toggle = false
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_CONTACT_ID(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.item.id
        }
      })
      .then(res => {
        this.$emit('destroyed', res.data.item)
        this.toggle = false
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.contactId = this.item.contactId
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      } else {
        this.$v.formData.$reset()
        this.formData.contactId = null
        this.$emit('update:isForm', false)
      }
    },
    isForm (value) {
      if (value) {
        this.toggle = true
      }
    }
  },
  created () {
    if (this.item) {
      this.setFormData(this.item)
    }
  },
  components: {
    DocConfigContactIdCardContact
  }
}
</script>

<style lang="css" scoped>
</style>
